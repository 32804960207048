import axios from "axios";
import { docReady, getCookie } from "./modules/tools";
import { mobileMenuFunctionality } from "./modules/header";
import { initForms, resendVerificationEmailHandler } from "./modules/forms";
import { accordionToggle } from "./modules/faq";
import { uppserSectionSlider, featuredSectionSlider, mostSeenSectionSlider } from "./modules/sliders";

import { setCountdowns } from "./components/countdown";
import { handleEgoSelects } from "./components/ego-select";
import { handleEllipsis } from "./components/ellipsis"
import { badgeFunctionality } from "./components/badge";
import { alertFunctionality } from "./components/alert";
import { handlePlanSelectors } from "./components/plan-selector";
import { updateCommissionsDetail } from "./components/commissions"

import { budgetTabs, initHomeCalculatorForm, initHomeBudgetForm, homeFiltersFunctionality, handleVideo, handleFeatured, bannerCarousel } from "./pages/home";
import { filtersFunctionality, viewItemList } from "./pages/buy";
import { vehicleDetailsGallery, handleOffer, handleSeeMore, handleLoginModal, viewItem } from "./pages/vehicle-details";
import { accordionProfile, handleTabs, handleVerifyModal, renewExpirationDate, accordionComissionsToggle } from "./pages/profile";
import { 
    ownerVehicleDelete, 
    userMatchCancellation, 
    DLRCertificationManage,
    DLRMatchConfirmation, 
    getContactData, 
    userHandleOffers,
    handleFavorite
} from "./pages/vehicle-actions";


axios.defaults.headers.common['X-CSRFToken'] = getCookie('csrftoken');


docReady(() => {
    mobileMenuFunctionality();
    initForms();
    resendVerificationEmailHandler();
    setCountdowns();
    uppserSectionSlider();
    featuredSectionSlider();
    mostSeenSectionSlider();
    accordionToggle();
    handleEgoSelects();
    handleEllipsis();
    badgeFunctionality();
    alertFunctionality();
    handlePlanSelectors();
    handleFavorite();

    // Home
    budgetTabs();
    initHomeCalculatorForm();
    initHomeBudgetForm();
    homeFiltersFunctionality();
    handleVideo();
    handleFeatured();
    bannerCarousel();

    // Buy Page
    filtersFunctionality();
    viewItemList();

    // Vehicle detail
    vehicleDetailsGallery(); 
    handleOffer();
    handleSeeMore();
    handleLoginModal();
    viewItem();

    ownerVehicleDelete();
    userMatchCancellation();
    DLRCertificationManage();
    updateCommissionsDetail();

    // Profile
    accordionProfile();
    handleTabs();
    handleVerifyModal();
    renewExpirationDate();
    accordionComissionsToggle();

    getContactData();
    userHandleOffers();
    DLRMatchConfirmation();
});