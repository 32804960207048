import gsap from 'gsap';
import axios from 'axios';
import EgoForm from '../components/ego-form'
import { filterNumber, filterMoneyAmount, vanillaFade, fillDataLayer } from '../modules/tools';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


gsap.registerEffect({
    name:"counter",
    extendTimeline: true,
    defaults: {
        start: 0,
        end: 0,
        duration: 0.5,
        ease: "power1",
        increment: 1,
    },
    effect: (targets, config) => {
        let tl = gsap.timeline()
        let num = targets[0].innerText.replace(/\,/g,'')
        targets[0].innerText = num
        
        tl.to(targets, {
            duration: config.duration, 
            innerText: config.end,
            modifiers: {
                innerText:function(innerText){
                    return  gsap.utils.snap(config.increment, innerText).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    
                }
            },
            ease: config.ease
        }, config.start)
            
        return tl
    }
});

export const budgetTabs = () => {
    const tabs = document.querySelector('.budget-tabs');
    
    if (tabs) {
        tabs.querySelectorAll('.budget-tabs__header__btn').forEach(btn => {
            btn.addEventListener('click', () => {
                if (!btn.classList.contains('--active')) {
                    tabs.querySelector('.budget-tabs__header__btn.--active')?.classList.remove('--active');
                    btn.classList.add('--active');

                    const target = btn.dataset.target,
                        activeTab = tabs.querySelector('.budget-tabs__tab.--active'),
                        targetTab = tabs.querySelector(`.budget-tabs__tab[data-tab="${target}"]`);

                    vanillaFade({
                        element: activeTab, 
                        enter: false, 
                        duration: 200, 
                        displayType: 'block', 
                        callback: () => {
                            activeTab.classList.remove('--active');

                            vanillaFade({
                                element: targetTab, 
                                enter: true, 
                                duration: 200, 
                                displayType: 'grid', 
                                callback: () => {
                                    targetTab.classList.add('--active');
                                }
                            });
                        }
                    });
                }
            });
        })
    }
}

export const initHomeCalculatorForm = () => {
    const form = document.getElementById('homeCalculatorForm');

    if (form) {
        const homeCalculatorForm = new EgoForm({
            element: form,
            submitType: 'POST',
            showMessages: false
        });

        const selectBodyBtn = form.querySelectorAll('.set-body-type'),
            downPaymentInput = form.querySelector('#downpayment_amount'),
            dowmPaymentInit = downPaymentInput.parentElement.dataset.init,
            monthlyFeeInput = form.querySelector('#monthly_fee'),
            monthlyFeeInit = monthlyFeeInput.parentElement.dataset.init,
            bodyInput = form.querySelector('input[name="body_type"]'),
            budgetInput = form.querySelector('input[name="budget"]'),
            budgetResult = form.querySelector('.budget-result'),
            serviceErrorAlert = form.querySelector('.service-error-alert'),
            submitButton = form.querySelector('button'),
            timeline = gsap.timeline();


        // Prefill and run
        if (dowmPaymentInit && monthlyFeeInit) {
            downPaymentInput.parentElement.classList.add('--filled');
            monthlyFeeInput.parentElement.classList.add('--filled');
            
            setTimeout(() => {
                downPaymentInput.value = filterMoneyAmount(dowmPaymentInit);
                monthlyFeeInput.value = filterMoneyAmount(monthlyFeeInit);
                runBudgetCalculator();
            }, 200);
        }
        
        
        selectBodyBtn.forEach(btn => {
            btn.addEventListener('click', e => {
                e.preventDefault();
                selectBodyBtn.forEach(item => item.classList.remove('--active'));
                btn.classList.add('--active');
                bodyInput.value = btn.dataset.body;
                bodyInput.dispatchEvent(new Event('change'));
            });
        });

        function isWithinBoundaries() {
            let validBoundries = true;
            
            const downPaymentField = downPaymentInput.parentElement;
            let downPaymentHasError = false;
            if (downPaymentField.dataset.min) {
                const downPaymentMin = parseInt(downPaymentField.dataset.min);
                if (parseInt(filterNumber(downPaymentInput.value)) <  downPaymentMin) {
                    validBoundries = false;
                    downPaymentHasError = true;
                    showFieldError(downPaymentField, 'Mínimo ' + filterMoneyAmount(downPaymentMin));
                }
            }
            if (downPaymentField.dataset.max) {
                const downPaymentMax = parseInt(downPaymentField.dataset.max);
                if (parseInt(filterNumber(downPaymentInput.value)) > downPaymentMax) {
                    validBoundries = false;
                    downPaymentHasError = true;
                    showFieldError(downPaymentField, 'Máximo ' + filterMoneyAmount(downPaymentMax));
                }
            }
            if (!downPaymentHasError) downPaymentField.classList.remove('--has-error');

            const monthlyFeeField = monthlyFeeInput.parentElement;
            let monthlyFeeHasError = false;
            if (monthlyFeeField.dataset.min) {
                const monthlyFeeMin = parseInt(monthlyFeeField.dataset.min);
                if (parseInt(filterNumber(monthlyFeeInput.value)) <  monthlyFeeMin) {
                    validBoundries = false;
                    monthlyFeeHasError = true;
                    showFieldError(monthlyFeeField, 'Mínimo ' + filterMoneyAmount(monthlyFeeMin));
                }
            }
            if (monthlyFeeField.dataset.max) {
                const monthlyFeeMax = parseInt(monthlyFeeField.dataset.max);
                if (parseInt(filterNumber(monthlyFeeInput.value)) > monthlyFeeMax) {
                    validBoundries = false;
                    monthlyFeeHasError = true;
                    showFieldError(monthlyFeeField, 'Máximo ' + filterMoneyAmount(monthlyFeeMax));
                }
            }
            if (!monthlyFeeHasError) monthlyFeeField.classList.remove('--has-error'); 

            return validBoundries;
        }

        function showFieldError(field, msg) {
            field.classList.add('--has-error');
            field.querySelector('.ego-form__field__error').innerHTML = msg; 
        }

        // Listen for changes
        async function runBudgetCalculator() {
            if (downPaymentInput.value != '' && monthlyFeeInput.value != '' && isWithinBoundaries()) {
                form.classList.add('--block');
                submitButton.classList.remove('disabled');
                submitButton.classList.add('loading');
                
                const downPayment = parseInt(filterNumber(form.querySelector('#downpayment_amount').value));
                const monthlyFee = parseInt(filterNumber(form.querySelector('#monthly_fee').value));
                const budget = await getBudget(downPayment, monthlyFee);
                if (budget) {
                    budgetInput.value = budget;
    
                    timeline.counter("#estimatedBudget", {
                        end: budget, 
                        increment: 10
                    });
                    form.classList.remove('--block');
                }
                else {
                    budgetResult.remove();
                    serviceErrorAlert.classList.remove('--hide');
                    submitButton.classList.add('disabled');
                }
                submitButton.classList.remove('loading');
            } else {
                submitButton.classList.add('disabled');
                timeline.counter("#estimatedBudget", {
                    end: 0, 
                    increment: 10
                });
            }
        }

        async function getBudget(downPayment, monthlyFee) {
            if (!downPayment || !monthlyFee) return 0;
            const result = await axios.post('/api/store/calculate_budget/', {
                downpayment_amount: downPayment,
                fee_amount: monthlyFee
            }).catch(error => false);
            return result.status === 200 ? result.data.budget + downPayment : 0;
        }

        let timeOut;
        function restart() {
            clearTimeout(timeOut);
            timeOut = setTimeout(runBudgetCalculator, 500);
        }

        function redirectWithBudgetParams() {
            // push data to dataLayer
            fillDataLayer({
                'event': 'comparar_presupuesto',
                'tipo_presupuesto': 'por cuotas',
                'pago_pie': form.querySelector('#downpayment_amount').value,
                'pago_cuota': form.querySelector('#monthly_fee').value,
                'vehículos_modelo': form.querySelector('.set-body-type.--active p')?.innerHTML || '',
                'precio_hasta': form.querySelector('input[name="budget"]').value
            })

            const body_type = bodyInput.value;
            const price_max = parseInt(budgetInput.value);
            const price_min = 0;
            const order = '-price';
            const qs = new URLSearchParams({body_type, price_min, price_max, order}).toString();
            window.location.href = `${form.action}?${qs}`;
        }

        downPaymentInput.addEventListener('input', restart);
        monthlyFeeInput.addEventListener('input', restart);
        submitButton.addEventListener('click', redirectWithBudgetParams);
    }
}

export const initHomeBudgetForm = () => {
    const form = document.getElementById('homeBudgetForm');

    if (form) {
        const homeBudgetForm = new EgoForm({
            element: form,
            submitType: 'POST',
            showMessages: false,
            onBeforeSubmit: () => {
                // push data to dataLayer
                fillDataLayer({
                    'event': 'comparar_presupuesto',
                    'tipo_presupuesto': 'por precio final',
                    'pago_pie': '',
                    'pago_cuota': '',
                    'vehículos_modelo': form.querySelector('.set-body-type.--active p')?.innerHTML || '',
                    'precio_hasta': form.querySelector('input[name="price_max"]').value
                })
            }
        });

        const selectBodyBtn = form.querySelectorAll('.set-body-type'),
            priceInput = form.querySelector('#budget_amount'),
            priceInputInit = priceInput.parentElement.dataset.init,
            bodyInput = form.querySelector('input[name="body_type"]'),
            submitBtn = form.querySelector('[type="submit"]'),
            rawPriceInput = form.querySelector('[name="price_max"]');
        
        const fillPriceIndicator = price => {
            const priceIndicator = form.querySelector('#fixedBudget');
            if (priceIndicator) {
                if (price) {
                    priceIndicator.innerHTML = price;
                    submitBtn.classList.remove('disabled');
                    submitBtn.disabled = false;
                    rawPriceInput.value = filterNumber(price);
                } else {
                    priceIndicator.innerHTML = '$0';
                    submitBtn.classList.add('disabled');
                    submitBtn.disabled = true;
                    rawPriceInput.value = '';
                }
            }
        }

        priceInput.parentElement.classList.add('--filled');
        priceInput.value = filterMoneyAmount(priceInputInit);
        fillPriceIndicator(priceInput.value);

        priceInput.addEventListener('input', () => fillPriceIndicator(priceInput.value));
        priceInput.addEventListener('paste', () => fillPriceIndicator(priceInput.value));

        selectBodyBtn.forEach(btn => {
            btn.addEventListener('click', e => {
                e.preventDefault();
                selectBodyBtn.forEach(item => item.classList.remove('--active'));
                btn.classList.add('--active');
                bodyInput.value = btn.dataset.body;
                bodyInput.dispatchEvent(new Event('change'));
            });
        });
    }
}

export const homeFiltersFunctionality = () => {
    const filtersForm = document.getElementById('homeFiltersForm');
    if (filtersForm) handleHomeFilters(filtersForm);
}

const handleHomeFilters = form => {
    const homeFiltersForm = new EgoForm({
        element: form,
        submitType: 'POST',
        showMessages: false,
        onBeforeSubmit: () => {
            form.querySelector('[name="price_max"]').value = filterNumber(form.querySelector('[name="price_max"]').value)
            fillDataLayer({
                'event': 'calculadora',
                'vehículos_modelo': form.querySelector('#model .option.--selected').innerText,
                'ubicacion': form.querySelector('#cityRegion .option.--selected').innerText,
                'presupuesto_estimado': form.querySelector('[name="price_max"]').value,
            })
        }
    });
    
    document.querySelectorAll('.filter-form--partial-clean')
            .forEach(cleanButton => {
                cleanButton.addEventListener('click', e => {
                    const fieldGroup = cleanButton.parentElement.parentElement;
                    fieldGroup.querySelectorAll('.ego-form__field').forEach(field => {
                        if (field.classList.contains('--select')) catalogfiltersForm.resetSelectField(field);
                        else if (field.classList.contains('--radio')) catalogfiltersForm.resetRadioField(field);
                        else catalogfiltersForm.resetField(field)
                    });
                });
            });
}

export const handleVideo = () => {
    const videoContainers = document.querySelectorAll('.video__container');

    if (videoContainers) {
        videoContainers.forEach((item) => {            
            const enlace = item.querySelector('.video__container__element__video');
            let videoSrc = enlace.getAttribute('src');
            
            if (videoSrc.includes("watch?v=")) {
                videoSrc = videoSrc.replace("watch?v=", "embed/") + "?autoplay=0";
            } else if (videoSrc.includes("feature=shared")) {
                videoSrc = videoSrc.replace("https://youtu.be/", "https://www.youtube.com/embed/").replace("feature=shared", "autoplay=0"); + "?autoplay=0";
            }

            enlace.setAttribute('src', videoSrc);

            const thumbnail = item.querySelector('.video__container__element__thumbnail');

            thumbnail.addEventListener('click', () => {
                const currentSrc = enlace.getAttribute('src');
                const newSrc = currentSrc.replace('autoplay=0', 'autoplay=1');
                
                setTimeout(() => {
                    thumbnail.style.display = 'none';
                }, 1000);

                enlace.setAttribute('src', newSrc);
            });

        });
    }
}

export const handleFeatured = () => {
    const selectedList = document.querySelectorAll(".vehicle-selected");
    if (selectedList) {
        selectedList.forEach(selected => {
            selected.addEventListener("click", (e) => {
                const href = selected.getAttribute("href");
                e.preventDefault();
                const model = selected.dataset.set;
                
                fillDataLayer({
                    'event': 'trackEvent',
                    'eventCategory': 'home',
                    'eventAction': 'destacados',
                    'eventLabel': model
                }) 
                window.location = href;
            });
        });
    }
};

export const bannerCarousel = () => {
    const container = document.querySelector('.banner-carousel');

    if (container) {
        const bannerCarouselSwiper = new Swiper(".banner-carousel__swiper", {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }
}