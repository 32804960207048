import EgoModal from '../components/ego-modal';
import EgoForm from '../components/ego-form';
import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';
import 'swiper/css';
import "swiper/css/lazy";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import { animatedScrollTo, filterMoneyAmount, filterNumber, fillDataLayer } from '../modules/tools';
import axios from 'axios';

export const vehicleDetailsGallery = () => {
    const container = document.getElementsByClassName('vehicle-detail__gallery');

    if (container) {
        const vehicleDetailsThumbs = new Swiper(".vehicle-detail__gallery .thumbs", {
            freeMode: true,
            slidesPerView: 4,
            spaceBetween: 8,
            watchSlidesProgress: true,
            breakpoints: {
                700: {
                    slidesPerView: 6,
                    spaceBetween: 16,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 8,
                },
                1200: {
                    slidesPerView: 6,
                    spaceBetween: 16,
                }
            }
        });
        const vehicleDetailsGallery = new Swiper(".vehicle-detail__gallery .gallery", {
            modules: [Navigation, Pagination, Thumbs],
            slidesPerView: 1,
            spaceBetween: 16,
            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            thumbs: {
                swiper: vehicleDetailsThumbs,
            },
        });
    }
}

export const handleOffer = () => {
    const offerFormElement = document.getElementById('offerForm');
    const financeModalElement = document.getElementById('financeModal');
    const financeFormElement = document.getElementById('financeModalForm');

    if (offerFormElement && financeModalElement) {
        const inputMethod = offerFormElement.querySelector('input[name="method"]');
        const inputPrice = offerFormElement.querySelector('input[name="price"]');
        const submitButton = offerFormElement.querySelector('.buy-button');
        const submitZoneButton = document.querySelector('.zone-buy-button');
        const offerErrorAlert = offerFormElement.querySelector('.offer-error-alert');
        const userType = offerFormElement.dataset.userType;

        const price = document.querySelector("#vehiclePrice")

        const paymentBox = document.querySelector('.payment-box');
        const cashBtn = paymentBox.querySelector('.payment-box__cash');
        const financeBtn = paymentBox.querySelector('.payment-box__finance');
        const preApprovalBox = paymentBox.querySelector('.pre-approval-box');

        // data for dataLayer
        // MutationObserver API is used to listen when the fees changes
        // because its imposible to listen when a hidden input value changes
        let downpaymentDL;
        let feesDL;
        const feesTextSpan = financeFormElement.querySelector('#fees .value-placeholder')
        let observer = new MutationObserver(() => {
            feesDL = feesTextSpan.innerHTML
        });
        observer.observe(feesTextSpan, {characterData: false, childList: true, attributes: false});

        const financeModal = new EgoModal({
            element: financeModalElement,
            onClose: () => {
                if (inputMethod.value === 'cash') {
                    resetFinanceModal();
                    if (cashBtn && financeBtn) {
                        cashBtn.classList.add('--selected');
                        financeBtn.classList.remove('--selected', '--active');
                    }
                }
            }
        });

        const financeForm = new EgoForm({
            element: financeFormElement,
            submitType: 'fetch',
            resetOnSuccess: false,
            showMessages: false,
            onSuccess: resp => {
                window.simulationData = {
                    renueve: null,
                    tradicional: null,
                    selected: null
                };
                for (const plan of resp.data) {
                    if (plan.smart_buy) window.simulationData.renueve = plan;
                    else window.simulationData.tradicional = plan;
                }
                completeFinanceInfo();

                // push data to dataLayer
                fillDataLayer({
                    'event': 'calcular_financiamiento',
                    'precio_hasta': inputPrice.value,
                    'pago_pie': downpaymentDL,
                    'cuotas': feesDL,
                    'vehículos_modelo': document.querySelector('.vehicle-detail__header__model').innerText,
                    'eventLabel': 'Calcular'
                })
            },
        });

        const resetFinanceModal = () => {
            financeForm.reset();
            financeModal.navToPage(1);

            if (inputMethod.value === 'cash' && window.simulationData) {
                window.simulationData = {
                    renueve: null,
                    tradicional: null,
                    selected: null
                };
            }
        }


        const completeDownpayment = (value) => {
            financeFormElement.querySelector('[name="downpayment"]').value = value;
            downpaymentDL = value;
        }
        financeFormElement.querySelector('[name="downpayment_mask"]')
            .addEventListener('input', e => completeDownpayment(filterNumber(e.currentTarget.value)))
        financeFormElement.querySelector('[name="downpayment_mask"]')
            .addEventListener('paste', e => completeDownpayment(filterNumber(e.currentTarget.value)));

        const offerForm = new EgoForm({
            element: offerFormElement,
            submitType: 'post',
            resetOnSuccess: false,
            showMessages: false,
        });

        // Payment box
        if (cashBtn && financeBtn) {
            cashBtn.addEventListener('click', e => {
                inputMethod.value = 'cash';
                inputPrice.value = cashBtn.dataset.price;
                cashBtn.classList.add('--selected');

                financeBtn.querySelector('.type').innerText = 'Financiado';
                financeBtn.querySelector('.price').innerText = '';
                price.innerText = filterMoneyAmount(cashBtn.dataset.price);
                price.classList.remove('--monthly')
                financeBtn.classList.remove('--selected', '--active');

                resetFinanceModal();
                showPreApproval(false);
            });
            financeBtn.addEventListener('click', e => {
                e.preventDefault();
                financeBtn.classList.add('--selected');
                cashBtn.classList.remove('--selected');
                if (inputMethod.value === 'cash') financeModal.open();
                else showPreApproval(true);
                
                // push data to dataLayer
                fillDataLayer({
                    'event': 'trackEvent',
                    'eventCategory': 'comprar',
                    'eventAction': 'cta',
                    'eventLabel': financeBtn.querySelector('.type').innerText
                })
            });
        }

        document.querySelectorAll('.open-finance-modal')
            .forEach(btn => {
                btn.addEventListener('click', () => financeModal.open());
            });


        financeModalElement.querySelectorAll('.plan-selector__button')
            .forEach(btn => {
                btn.addEventListener('click', e => {
                    const target = btn.dataset.target;
                    financeModalElement.querySelector('.plan-data.--show')?.classList.remove('--show');
                    financeModalElement.querySelector(`.plan-data[data-plan="${target}"]`)?.classList.add('--show');

                    if (window.simulationData) window.simulationData.selected = target;
                });
            });

        financeModalElement.querySelector('.set-finance-plan')
            .addEventListener('click', e => {
                if (window.simulationData && window.simulationData.selected) {
                    inputMethod.value = window.simulationData.selected;
                    inputPrice.value = '';
                    if (financeBtn) {
                        financeBtn.querySelector('.type').innerText = 'Financiado';
                        // financeBtn.querySelector('.price').innerText = filterMoneyAmount(Math.ceil(window.simulationData[window.simulationData.selected].fee_value));
                        price.innerText = filterMoneyAmount(Math.ceil(window.simulationData[window.simulationData.selected].fee_value)) + ' ';
                        price.classList.add('--monthly');
                        financeBtn.classList.add('--active');
                    }

                    financeModal.close();

                    showPreApproval(true);
                    hidePreApprovalAlert();
                    enableSubmitButton(false);
                }
            });

        financeModalElement.querySelector('.plan-tyc .show-more')
            .addEventListener('click', e => {
                e.preventDefault();
                const text = e.target.previousElementSibling;
                text.classList.remove('--ellipsis');
                e.target.remove();
            });



        offerFormElement.querySelector('input[name="tyc"]')?.addEventListener('change', e => {
            enableSubmitButton(e.target.checked);
            // if (inputMethod.value === 'cash') enableSubmitButton(e.target.checked);
            // else enableSubmitButton(e.target.checked && preApproved.value);
        });


        //////////////////////////
        // BUY / OFFER SUBMISSION
        if (userType && submitButton) {
            submitButton.addEventListener('click', e => {
                if (userType === 'dealer') {
                    if (offerForm.validateForm() === true) offerCar();
                }
                else if (userType === 'user' && submitButton.hasAttribute('data-open-zone-modal')) handleZoneModal();
                else if (userType === 'user') buyCar();
            });
        }

        if (submitZoneButton) {
            submitZoneButton.addEventListener('click', e => {
                buyCar();
            });
        }

        const offerSubmitting = submitting => {
            if (submitting) {
                offerErrorAlert.classList.add('--hide');
                document.body.classList.add('block');
                submitButton.classList.add('loading');
            } else {
                document.body.classList.remove('block');
                submitButton.classList.remove('loading');
            }
        }

        const offerCar = () => {
            const offer = offerFormElement.querySelector('[name="dealer_offer"]')?.value;

            if (offer) {
                const offerAlert = document.querySelector('.offer-sent-message'),
                    offerAlertMessage = offerAlert.querySelector('.message');
                offerAlertMessage.innerText = offerAlertMessage.innerText.replace('$$$', offer);

                offerSubmitting(true);
                axios.post('/api/store/make_offer/', {
                    license_plate: offerFormElement.dataset.license,
                    amount: parseInt(filterNumber(offer))
                })
                    .then(resp => {
                        if (resp.status === 201) {
                            offerAlert.classList.remove('--hide');
                            submitButton.blur();
                            submitButton.classList.add('disabled');
                            animatedScrollTo(document.querySelector('.page-top-navigation'), 200);

                            // push data to dataLayer
                            fillDataLayer({
                                'event': 'ofertar',
                                'precio_hasta': parseInt(filterNumber(offer)),
                                'vehículos_modelo': document.querySelector('.vehicle-detail__header__model').innerHTML,
                            })
                        }
                        else offerErrorAlert.classList.remove('--hide');
                    })
                    .catch(error => {
                        offerErrorAlert.classList.remove('--hide');
                    })
                    .finally(() => {
                        offerSubmitting(false);
                    });
            }
        }

        const handleZoneModal = () => {
            const zoneModalElement = document.getElementById('zoneModal');
        
            if (zoneModalElement) {        
                const zoneModal = new EgoModal({
                    element: zoneModalElement,
                });
                zoneModal.open()
            }
        }

        const buyCar = () => {
            const make = `${document.querySelector('.vehicle-detail__header__model').innerText}`
            offerSubmitting(true);
            let offerData = null;
            if (inputMethod.value === 'cash') {
                offerData = {
                    license_plate: offerFormElement.dataset.license,
                    purchase_method: 0
                }
            } else {
                const plan = window.simulationData[window.simulationData.selected];
                const preApproved = offerFormElement.querySelector('[name="preapproved"]').value === 'true';

                offerData = {
                    license_plate: offerFormElement.dataset.license,
                    purchase_method: 1,
                    financing_details: {
                        downpayment: plan.downpayment_amount,
                        loan_amount: plan.credit_total_cost,
                        fees: plan.number_fees,
                        interest_rate: plan.rate_interest,
                        smart_buy: plan.smart_buy,
                        credit_pre_approved: preApproved,
                        income: incomeInput ? parseInt(filterNumber(incomeInput.value)) : 0,
                        future_value_guaranteed: plan.future_value_guaranteed ?? 0
                    }
                }
            }
            axios.post('/api/store/vehicle_buy/', offerData)
                .then(resp => {
                    if (resp.status === 201) {
                        // push data to dataLayer
                        fillDataLayer({
                            'event': 'pre_reservar',
                            'precio_hasta': inputPrice.value,
                            'vehículos_modelo': document.querySelector('.vehicle-detail__header__model').innerHTML,
                            'eventLabel': submitButton.innerHTML,
                        })
                        
                        window.location.href = `/prereserva-exitosa?v=${make}`
                    } else {
                        offerErrorAlert.classList.remove('--hide');
                    }
                })
                .catch(error => {
                    console.log(error);
                    offerErrorAlert.classList.remove('--hide');
                })
                .finally(() => {
                    offerSubmitting(false);
                });
        }


        const completeFinanceInfo = () => {
            if (window.simulationData) {
                if (window.simulationData.renueve) {
                    populatePlanData('renueve', window.simulationData.renueve);
                    financeModalElement.querySelector(`.plan-selector__button[data-target="renueve"]`).classList.remove('--hidden');
                    financeModalElement.querySelector(`.plan-selector__button[data-target="renueve"]`).classList.add('--selected');
                    financeModalElement.querySelector(`.plan-data[data-plan="renueve"]`).classList.add('--show');
                    window.simulationData.selected = 'renueve';
                }
                if (window.simulationData.tradicional) {
                    populatePlanData('tradicional', window.simulationData.tradicional);
                    financeModalElement.querySelector(`.plan-selector__button[data-target="tradicional"]`).classList.remove('--hidden');
                    if (!window.simulationData.renueve) {
                        financeModalElement.querySelector(`.plan-selector__button[data-target="tradicional"]`).classList.add('--selected');
                        financeModalElement.querySelector(`.plan-data[data-plan="tradicional"]`).classList.add('--show');
                        window.simulationData.selected = 'tradicional';
                    }
                }

                financeModal.nextPage();
            }
        }

        const populatePlanData = (planName, plan) => {
            const planSelector = financeModalElement.querySelector(`.plan-selector__button[data-target="${planName}"]`);
            const planData = financeModalElement.querySelector(`.plan-data[data-plan="${planName}"]`);

            planSelector.classList.remove('--hidden');
            planSelector.dataset.value = plan.fee_value;
            planSelector.querySelector('.price i').innerHTML = filterMoneyAmount(plan.fee_value, '$', true);
            planData.querySelector('.downpayment_amount').innerHTML = filterMoneyAmount(plan.downpayment_amount, '$', true);
            planData.querySelector('.credit_total_cost').innerHTML = filterMoneyAmount(plan.credit_total_cost, '$', true);
            planData.querySelector('.equivalent_annual_load').innerHTML = plan.equivalent_annual_load + '%';
            planData.querySelector('.fee_value').innerHTML = filterMoneyAmount(plan.fee_value, '$', true);
            if (planData.querySelector('.future_value_guaranteed') && plan.future_value_guaranteed) {
                planData.querySelector('.future_value_guaranteed').innerHTML = filterMoneyAmount(plan.future_value_guaranteed, '$', true);
            }
        }

        // PRE-APPROVAL
        const preApproveBtn = offerFormElement.querySelector('.finance-preapproval'),
            preApproved = offerFormElement.querySelector('[name="preapproved"]'),
            incomeField = document.getElementById('incomeField'),
            incomeFieldError = incomeField?.querySelector('.ego-form__field__error'),
            incomeInput = offerFormElement.querySelector('[name="income"]'),
            incomeAlert = offerFormElement.querySelector('.pre-approval-alert'),
            incomeAlertMessage = incomeAlert?.querySelector('.message'),
            successMessage = "Tu solicitud de pre-aprobación ha sido exitosa. Un representante se estará comunicando contigo.",
            failMessage = "Con la información ingresada no podemos concluir una respuesta satisfactoria, escríbenos al WhatsApp +56977891685 para continuar el proceso evaluación",
            tycMafField = document.getElementById('tycMaf'),
            tycMafCheck = tycMafField?.querySelector('[name="tyc_maf"]'),
            tycMafFieldError = tycMafField?.querySelector('.ego-form__field__error');

        if (preApproveBtn && incomeField) {
            preApproveBtn.addEventListener('click', e => {
                const plan = window.simulationData[window.simulationData.selected];
                let canPreApprove = true;

                // Validate income
                if (!incomeInput.value) {
                    incomeFieldError.innerHTML = "Completá tu ingreso."
                    incomeField.classList.add('--has-error');
                    canPreApprove = false;
                } else if (parseInt(filterNumber(incomeInput.value)) < incomeField.dataset.min) {
                    incomeFieldError.innerHTML = `Minimo ${filterMoneyAmount(incomeField.dataset.min)}`;
                    incomeField.classList.add('--has-error');
                    canPreApprove = false;
                }

                // Validate TYC Maf
                if (!tycMafCheck.checked) {
                    tycMafFieldError.innerHTML = "Debes aceptar los térnimos y condiciones."
                    tycMafField.classList.add('--has-error');
                    canPreApprove = false;
                }

                if (canPreApprove) {
                    document.body.classList.add('block');
                    preApproveBtn.classList.add('loading');
                    axios.post('/api/store/credit_pre_approval/', {
                        license_plate: offerFormElement.dataset.license,
                        downpayment: plan.downpayment_amount,
                        salary: parseInt(filterNumber(incomeInput.value)),
                        fees: plan.number_fees,
                        fee_amount: plan.fee_value,
                        guaranteed_future_value: plan.future_value_guaranteed,
                        equivalent_annual_load: plan.equivalent_annual_load
                    })
                        .then(resp => {
                            preApproved.value = resp.data.pre_approved;
                            incomeAlertMessage.innerText = resp.data.pre_approved ? successMessage : failMessage
                        })
                        .catch(err => {
                            console.log(err);
                            preApproved.value = 'false';
                            incomeAlertMessage.innerText = failMessage;
                        })
                        .finally(() => {
                            document.body.classList.remove('block');
                            preApproveBtn.classList.remove('loading');
                            showPreApprovalAlert();

                            if (offerFormElement.querySelector('input[name="tyc"]:checked')) enableSubmitButton(true);
                        });
                }
            });
        }


        const showPreApproval = show => {
            if (preApprovalBox) {
                if (show) {
                    incomeField.classList.remove('--filled');
                    incomeInput.value = '';
                    preApproved.value = '';
                    preApprovalBox.style.height = preApprovalBox.scrollHeight + 'px';
                    setTimeout(() => preApprovalBox.style.height = 'auto', 200);
                } else {
                    preApprovalBox.style.height = '0';
                    incomeField.classList.remove('--required');
                }
            }
        }

        const hidePreApprovalAlert = () => {
            if (offerFormElement) {
                if (offerFormElement.querySelector('.finance-preapproval')) offerFormElement.querySelector('.finance-preapproval')?.classList.remove('--hidden');
                if (document.getElementById('incomeField')) document.getElementById('incomeField').classList.remove('--hidden');
                if (offerFormElement.querySelector('.pre-approval-alert')) offerFormElement.querySelector('.pre-approval-alert').classList.add('--hide');
                if (offerFormElement.querySelector('.checkbox-maf')) offerFormElement.querySelector('.checkbox-maf').classList.remove('--hidden');
            }
        }

        const showPreApprovalAlert = () => {
            if (offerFormElement) offerFormElement.querySelector('.finance-preapproval').classList.add('--hidden');
            document.getElementById('incomeField').classList.add('--hidden');
            if (offerFormElement) offerFormElement.querySelector('.pre-approval-alert').classList.remove('--hide');
            if (offerFormElement) offerFormElement.querySelector('.checkbox-maf').classList.add('--hidden');
        }

        const enableSubmitButton = enable => {
            const userExists = document.querySelector('meta[name="user"]');

            if (userExists) {
                if (enable) {
                    submitButton.classList.remove('disabled');
                    submitButton.disabled = false;
                } else {
                    submitButton.classList.add('disabled');
                    submitButton.disabled = true;
                }
            }
        }
    }
}

export const handleSeeMore = () => {
    const seeMoreBtn = document.querySelector('.see-more-btn');
    const seeMoreContent = document.querySelector('.see-more-content');

    if (seeMoreBtn && seeMoreContent) {
        seeMoreBtn.addEventListener('click', () => {
            seeMoreContent.classList.toggle('--show');
            seeMoreBtn.innerHTML = seeMoreContent.classList.contains('--show') ? 'Ver menos' : 'Ver más';
        });
    }
}

export const handleLoginModal = () => {
    const loginModalElement = document.getElementById('loginModal');

    if (loginModalElement) {
        const openLoginBtns = document.querySelectorAll('[data-open-login-modal]');

        const loginModal = new EgoModal({
            element: loginModalElement,
        });

        openLoginBtns.forEach(btn => {
            btn.addEventListener('click', (e) => {
                loginModal.open()
            })
        })

        // Check if error_message exists in url and open the modal
        const url = window.location.href;
        const errorParam = new URL(url).searchParams.get("error_message");
        if (errorParam) loginModal.open();
    }
}

export const viewItem = () => {
    const selectItems = document.querySelectorAll('.select_item')
    const results = document.querySelector('.results_data-set')

    const featuredItems = document.querySelectorAll('.vehicle-selected')
    const featured = document.querySelector('.featured')

    const vehicleDataSet = document.querySelector('.vehicle_data-set')

    const origin = window.location.origin + '/'
    const ulrPrev = document.referrer.includes('comprar') ? false : document.referrer == origin ? false : true

    if(results && selectItems) {
        const list = JSON.parse(results.dataset.set);

        const currentURL = window.location.href;
        const urlParams = new URLSearchParams(new URL(currentURL).search);
        const pageNumber = urlParams.get('page') || 1;

        selectItems.forEach(selectItem => {
            selectItem.addEventListener('click', (e) => {
                const href = selectItem.getAttribute("href");
                e.preventDefault()

                const selected = list.filter(item => item.id == selectItem.id)
                const vehicleData = selected[0]
                const selectedIndex = list.findIndex(item => item.id === selectItem.id) + 1;

                dataLayer.push({ ecommerce: null })
                fillDataLayer({
                    'event': 'view_item',
                    'ecommerce': {
                        'currency': 'CLP',
                        'value': vehicleData.price,
                        'items': [
                            {
                                'item_id': vehicleData.id,
                                'item_name': vehicleData.model + ' ' + vehicleData.version,
                                'affiliation': vehicleData.branch_name,
                                'index': selectedIndex,
                                'item_brand': vehicleData.model,
                                'item_category': vehicleData.body_type,
                                'item_category2': vehicleData.version,
                                'item_category3': vehicleData.year,
                                'item_list_id': pageNumber,
                                'item_list_name': 'comprar',
                                'item_variant': vehicleData.color,
                                'location_id': vehicleData.branch_id,
                                'price': vehicleData.price,
                                'quantity': 1
                            }
                        ]
                    }
                })
                fillDataLayer({
                    'event': 'select_item',
                    'ecommerce': {
                        'currency': 'CLP',
                        'value': vehicleData.price,
                        'items': [
                            {
                                'item_id': vehicleData.id,
                                'item_name': vehicleData.model + ' ' + vehicleData.version,
                                'affiliation': vehicleData.branch_name,
                                'index': selectedIndex,
                                'item_brand': vehicleData.model,
                                'item_category': vehicleData.body_type,
                                'item_category2': vehicleData.version,
                                'item_category3': vehicleData.year,
                                'item_list_id': pageNumber ? pageNumber : 1,
                                'item_list_name': 'comprar',
                                'item_variant': vehicleData.color,
                                'location_id': vehicleData.branch_id,
                                'price': vehicleData.price,
                                'quantity': 1
                            }
                        ]
                    }
                })
                window.location = href;
            })
        })
    } else if (featured && featuredItems) {
        const list = JSON.parse(featured.dataset.set);

        featuredItems.forEach(featuredItem => {
            featuredItem.addEventListener('click', (e) => {
                const href = featuredItem.getAttribute("href");
                e.preventDefault()

                const selected = list.filter(item => item.id == featuredItem.id)
                const vehicleData = selected[0]
                const selectedIndex = list.findIndex(item => item.id === featuredItem.id) + 1;

                dataLayer.push({ ecommerce: null })
                fillDataLayer({
                    'event': 'view_item',
                    'ecommerce': {
                        'currency': 'CLP',
                        'value': vehicleData.price,
                        'items': [
                            {
                                'item_id': vehicleData.id,
                                'item_name': vehicleData.model + ' ' + vehicleData.version,
                                'affiliation': vehicleData.branch_name,
                                'index': selectedIndex,
                                'item_brand': vehicleData.model,
                                'item_category': vehicleData.body_type,
                                'item_category2': vehicleData.version,
                                'item_category3': vehicleData.year,
                                'item_list_id': 1,
                                'item_list_name': 'destacados',
                                'item_variant': vehicleData.color,
                                'location_id': vehicleData.branch_id,
                                'price': vehicleData.price,
                                'quantity': 1
                            }
                        ]
                    }
                })

                window.location = href;
            })
        })
    } else if (vehicleDataSet && ulrPrev) {
        const vehicleData = JSON.parse(vehicleDataSet.dataset.set)
        
        dataLayer.push({ ecommerce: null })
        fillDataLayer({
            'event': 'view_item',
            'ecommerce': {
                'currency': 'CLP',
                'value': vehicleData.price,
                'items': [
                    {
                        'item_id': vehicleData.id,
                        'item_name': vehicleData.model + ' ' + vehicleData.version,
                        'affiliation': vehicleData.branch_name,
                        'index': 1,
                        'item_brand': vehicleData.model,
                        'item_category': vehicleData.body_type,
                        'item_category2': vehicleData.version,
                        'item_category3': vehicleData.year,
                        'item_list_id': 1,
                        'item_list_name': '',
                        'item_variant': vehicleData.color,
                        'location_id': vehicleData.branch_id,
                        'price': vehicleData.price,
                        'quantity': 1
                    }
                ]
            }
        })
    }
}
